
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const labelPadding = '10px 12px';

const StyledButton = styled.button.attrs({className: 'small'})`
  &,
  &:visited {
    text-decoration: none;
    display: block;
    position: relative;
    color: ${props => props.theme.buttonColor};
    background: ${props => props.theme.buttonBackgroundColor};
    border: none;
    border-left-width: ${props => props.theme.buttonLinePixels}px;
    border-left-color: ${props => props.theme.buttonLineColor};
    border-left-style: solid;
    width: 100%;
    height: ${props => props.short ? props.theme.buttonHeightShortPixels : props.theme.buttonHeightPixels}px;
    padding: 0;
    outline: none;
    text-align: left;
    filter: ${props => props.theme.buttonShadowFilter};
    z-index: 10;
    transition: all 0.1s;
  }

  &.accent:not(:disabled) {
    color: ${props => props.theme.buttonAccentColor};
    background: ${props => props.theme.buttonAccentBackgroundColor};
    border-left-color: ${props => props.theme.buttonAccentLineColor};
  }

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &.selected {
    color: ${props => props.theme.buttonActiveColor};
    background: ${props => props.theme.buttonActiveBackgroundColor};
    border-left-color: ${props => props.theme.buttonActiveLineColor};
  }

  &.filter.selected {
    ::after {
      content: '\\2715';
      position: absolute;
      top: 0;
      right: 0;
      padding: ${labelPadding};
      // color: ${props => props.theme.buttonActiveLineColor};
    }
  }

  &:active:not(:disabled) {
    filter: none;
    z-index: auto;
  }

  &:focus {
    outline: ${props => props.theme.buttonFocusOutline};
    outline-offset: 2px;
  }

  &:disabled {
    filter: none;
    opacity: 0.5;
    z-index: auto;
  }
`;

// Avoid vertically centered button text
const ButtonLabel = styled.span`
  display: block;
  height: inherit;
  padding: ${labelPadding};
`;

const Button = ({ children, ...props }) => (
  <StyledButton {...props}>
    <ButtonLabel>{children}</ButtonLabel>
  </StyledButton>
);

const ButtonLink = (props) => <Button as={Link} {...props}/>;
const ButtonA = (props) => <Button as="a" {...props}/>;

export { Button, ButtonLink, ButtonA };
