import styled from 'styled-components';
import media from './media';

const multiplier = props => props.gapMultiplier || 1;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: ${props => - props.theme.columnGapPixelsMob}px;
  padding-left: ${props => props.outerGap ? props.theme.columnGapPixelsMob : 0}px;
  padding-right: ${props => props.outerGap ? props.theme.columnGapPixelsMob : 0}px;

  ${media.tab`
    margin-right: ${props => - props.theme.columnGapPixelsTab}px;
    padding-left: ${props => props.outerGap ? props.theme.columnGapPixelsTab : 0}px;
    padding-right: ${props => props.outerGap ? props.theme.columnGapPixelsTag : 0}px;
  `}

  ${media.desk`
    margin-right: ${props => - props.theme.columnGapPixelsDesk}px;
    padding-left: ${props => props.outerGap ? props.theme.columnGapPixelsDesk : 0}px;
    padding-right: ${props => props.outerGap ? props.theme.columnGapPixelsDesk : 0}px;
  `}

  >* {
    padding-right: ${props => props.theme.columnGapPixelsMob * multiplier(props)}px;
    padding-bottom: ${props => props.theme.columnGapPixelsMob * multiplier(props)}px;

    ${media.tab`
      padding-right: ${props => props.theme.columnGapPixelsTab * multiplier(props)}px;
      padding-bottom: ${props => props.theme.columnGapPixelsTab * multiplier(props)}px;
    `}

    ${media.desk`
      padding-right: ${props => props.theme.columnGapPixelsDesk * multiplier(props)}px;
      padding-bottom: ${props => props.theme.columnGapPixelsDesk * multiplier(props)}px;
    `}
  }
`;

export default FlexRow;