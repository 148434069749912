import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  label {
    .input-messages {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }

    .input-messages__label {
      color: ${props => props.theme.formLabelColor};
      flex-grow: 1;
    }

    .input-messages__error {
      color: ${props => props.theme.formMessageColor};
      flex-grow: 1;
      text-align: right;
    }

    input,
    textarea {
      border-radius: 0;
      vertical-align: bottom;
    }

    input[type="text"],
    input[type="email"],
    textarea {
      -webkit-appearance: none;
      outline: none;
      width: 100%;
      padding-left: 8px;
      padding-right: 8px;
      resize: none;
      color: ${props => props.theme.formInputTextColor};
      min-height: ${props => props.theme.buttonHeightPixels}px;
      border: 1px solid ${props => props.theme.formBorderColor};
      box-shadow: ${props => props.theme.formFieldBoxShadow};

      :focus {
        box-shadow: ${props => props.theme.formFieldBoxShadowFocussed};
      }
    }

    input[type="checkbox"] {
      display: block;
      height: 13px;

      @supports (appearance: none) {
        appearance: none;
        opacity: 0;
        z-index: -100;
        position: absolute;

        ~ .checkmark {
          position: relative;
          width: 25px;
          height: 25px;
          background: #fff;
          border: 2px solid ${props => props.theme.formBorderColor};
          box-shadow: ${props => props.theme.formFieldBoxShadow};
        }

        :focus,
        :active {
          ~ .checkmark {
            box-shadow: ${props => props.theme.formFieldBoxShadowFocussed};
          }
        }

        :checked {
          ~ .checkmark {
            border-color: ${props => props.theme.formAccentColor};

            ::after {
              content: '';
              position: absolute;
              top: 1px;
              left: 7px;
              width: 7px;
              height: 17px;
              border: solid ${props => props.theme.formBorderColor};
              border-width: 0 3px 3px 0;
              transform: rotate(45deg);
            }
          }
        }
      }
    }

    &.error {
      .input-messages__label {
        color: ${props => props.theme.formErrorTextColor};
      }

      input[type="text"],
      input[type="email"],
      textarea {
        border-color: ${props => props.theme.formBorderErrorColor};
      }
    }
  }
`;

const InputContainer = ({ children, ...props }) => (
  <Container {...props}>
    {children}
  </Container>
);

export default InputContainer;